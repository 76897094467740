jQuery(document).ready(function($) {
  var $allVideos = $('iframe[src^=\'https://www.youtube.com\']');
  $allVideos.each(function() {
    /*$(this).data('aspectRatio', this.height / this.width)
        // and remove the hard coded width/height
        .removeAttr('height')
        .removeAttr('width');
    var $el = $(this);
    $el
    .width(newWidth)
    .height(newWidth * $el.data('aspectRatio'));*/
    $(this).wrap('<div class=\'responsive-video\'></div>');
  });
  /*var $allContentImg = $('.b-module img');
  $allContentImg.each(function() {
    $(this).removeAttr('style');
  });*/
});
