function calcVH() {
    var menu = $('.menu');
    maxHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - 39 + 'px';
    menu.css('maxHeight', maxHeight);
    menu.css('height', maxHeight);
}

jQuery(document).ready(function($) {
    $('.openclose__open').on('click', function() {
        $('body').addClass('body_menu_opened');
        calcVH();
        window.addEventListener('resize', calcVH, true);
        window.addEventListener('onorientationchange', calcVH, true);
        return false;
    });
    $('.openclose__close').on('click', function() {
        $('body').removeClass('body_menu_opened');
        window.removeEventListener('resize', calcVH, true);
        window.removeEventListener('onorientationchange', calcVH, true);
        return false;
    });
    var ssmMenuItems = $('.menu_size_supersmall li a');
    var ssmMenuItemsLi = $('.menu_size_supersmall li');

    ssmMenuItems.on('click', function(e) {
        var childLength = $(this).parent('li').find('ul').length;

        $(ssmMenuItemsLi).each(function() {
            $(this).removeClass('active');
        });
        if (childLength) {
            $(this).parents('li').addClass('active');
        }
        if (childLength === 0) {
            $('body').toggleClass('body_menu_opened');
        }
        return childLength === 0; // если нет подпунктов, то кликабельный пункт меню в мобильной версии, в противном случаее, разворачиваем
    });

    $('.menu_size_default ul > li').on('mouseover', function() {
        if ($(this).children().length > 1) {
            $('.menu_size_default > ul > li').each(function(index, li) {
                if ($(li).hasClass('active')) {
                    $(li).addClass('nohover');
                }
            });
        }
    });

    $('.menu_size_default ul > li > a:not(:last-child)').
        on('mouseout', function() {
            $('.menu_size_default > ul > li.nohover').removeClass('nohover');
        });

});
