jQuery(document).ready(function($) {
    if ($('.basket_number').length) {
        $.ajax({
            url: '/en/basket/json',
            dataType: 'json',
            async: false,
            success: function(data) {
                $('.basket_number').text(data.count);
                if (data.count > 0) {
                    $('.header__cart').addClass('header__cart_visible_yes')
                }
            },
            error: function(data) {
                alert('Internal error');
            }
        });
    }

    $('.showInquire').on('touchstart click', function() {
        $('.basket__order').hide();
        var id = $(this).data('id');
        var form = '#form-order-'+id;
        var scrl = $('#scrl-'+id);

        $(form).show();
        $('html, body').animate({
            scrollTop: scrl.offset().top
        }, 1000);
        return false;
    });
});
